import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PropertyAgentFragment } from '../../../gatsby-graphql';

const AgentInfo: React.FunctionComponent<PropertyAgentFragment> = ({
  name,
  location,
  picture,
}) => (
  <Container>
    <GatsbyImage
      image={picture?.gatsbyImageData}
      alt={picture?.description as string}
    />
    <Content>
      <span>{name}</span>
      <span>{location}</span>
    </Content>
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  img {
    border-radius: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  color: ${(props) => props.theme.colors.dark.primary};
  span:last-of-type {
    color: ${(props) => props.theme.colors.dark.primary};
    font-size: 12px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default React.memo(AgentInfo);
