import React from 'react';
import { graphql, PageRendererProps } from 'gatsby';
import Layout from '../components/Layout/Layout';
import { PropertyInternalPageQuery } from '../../gatsby-graphql';
import PropertyDetail from '../components/PropertyDetail/PropertyDetail';

interface IPropertyInternalPageTemplateProps extends PageRendererProps {
  data: PropertyInternalPageQuery;
}

const PropertyInternalPageTemplate: React.FC<IPropertyInternalPageTemplateProps> = ({
  data: { navigation, footer, property },
  location,
}) => (
  <Layout
    {...{ navigation, footer }}
    title={property?.name as string}
    description={property?.shortDescription as string}
    image={property?.images[0].file.url as string}
  >
    <PropertyDetail {...property} locationHref={location.href} />
  </Layout>
);

export const pageQuery = graphql`
  query PropertyInternalPage($propertyId: String!) {
    ...SiteNavigation
    property: contentfulProperty(id: { eq: $propertyId }) {
      ...PropertyDetail
    }
  }
`;

export default PropertyInternalPageTemplate;
