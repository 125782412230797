import React from 'react';
import styled from 'styled-components';
import { PropertyDetailFragment } from '../../../gatsby-graphql';

interface IPropertySpecsGridProps {
  specs?: PropertyDetailFragment['propertySpecs'];
}

const PropertySpecsGrid: React.FunctionComponent<IPropertySpecsGridProps> = ({
  specs,
}) => (
  <Container>
    {specs?.map((spec) => (
      <div key={spec?.id as string}>{spec?.name}</div>
    ))}
  </Container>
);

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${(props) => props.theme.mq.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }
  gap: 1rem;
  div {
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);
    background-color: ${(props) => props.theme.colors.lightSurface};
  }
`;

export default React.memo(PropertySpecsGrid);
