import React from 'react';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import styled from 'styled-components';

interface IPropertyDetailRichTextProps {
  data: RenderRichTextData<ContentfulRichTextGatsbyReference>;
}

const PropertyDetailRichText: React.FunctionComponent<IPropertyDetailRichTextProps> = ({
  data,
}) => <Container>{renderRichText(data)}</Container>;

const Container = styled.div`
  p {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.2px;
  }
`;

export default PropertyDetailRichText;
