import React, { useEffect, useRef } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import styled, { useTheme } from 'styled-components';
import mapboxGl from '!mapbox-gl';

interface IMapboxProps {
  lat?: number | null;
  lon?: number | null;
}

const Mapbox: React.FunctionComponent<IMapboxProps> = ({ lat, lon }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { colors } = useTheme();
  const token =
    'pk.eyJ1Ijoiam9zZWd1ZXJyZXJvIiwiYSI6ImNrbzB0ZzBzYzBnMG0ycG15OTQwbTloZGEifQ.h9UQ6HWkPgxqoVBUOCz0Fw';

  useEffect(() => {
    if (!containerRef.current) return;
    mapboxGl.accessToken = token;
    const map = new mapboxGl.Map({
      container: containerRef.current,
      style: 'mapbox://styles/joseguerrero/cko0tsmwb09pi17po6qsg9teh',
      center: [Number(lon), Number(lat)],
      zoom: 14,
      interactive: false,
    });
    new mapboxGl.Marker({ color: colors.primary })
      .setLngLat([Number(lon), Number(lat)])
      .addTo(map);
  }, []);

  return (
    <Container>
      <div ref={containerRef} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  & > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export default React.memo(Mapbox);
