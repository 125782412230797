import React from 'react';
import styled from 'styled-components';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { buttonBasicStyle } from '../../styles/globals/Buttons';
import { WhatsAppIcon } from '../../icons';

interface IWhatsAppButtonProps extends React.ComponentPropsWithoutRef<'a'> {
  loading?: boolean;
  children: React.ReactNode,
}

const WhatsAppButton: React.FunctionComponent<IWhatsAppButtonProps> = ({
  children,
  ...props
}) => (
  <StyledButton {...props}>
    <StyledWhatsAppIcon />
    {children}
  </StyledButton>
);

const StyledButton = styled.a`
  ${buttonBasicStyle};
  width: 100%;
  background-color: #455a64;
  border-color: #455a64;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
  padding-bottom: 7px;
`;

const StyledWhatsAppIcon = styled(WhatsAppIcon)`
  height: 24px;
  margin-right: 0.5rem;
`;

export default React.memo(WhatsAppButton);
