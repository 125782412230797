import React from 'react';
import styled from 'styled-components';
import { PropertyDetailFragment } from '../../../gatsby-graphql';
import { BathIcon, BedIcon, BlueprintIcon, CarIcon } from '../../icons';

interface IPropertyDetailInfoGridProps {
  bedroomsCount: PropertyDetailFragment['bedroomsCount'];
  bathroomsCount: PropertyDetailFragment['bathroomsCount'];
  garagesCount: PropertyDetailFragment['garagesCount'];
  externalArea: PropertyDetailFragment['externalArea'];
  internalArea: PropertyDetailFragment['internalArea'];
  propertyType: PropertyDetailFragment['propertyType'];
}

const PropertyDetailInfoGrid: React.FunctionComponent<IPropertyDetailInfoGridProps> = ({
  bedroomsCount,
  bathroomsCount,
  externalArea,
  internalArea,
  garagesCount,
}) => (
  <Container>
    <div>
      <BedIcon />
      <span>{bedroomsCount} Dormitorios</span>
    </div>
    <div>
      <BathIcon />
      <span>{bathroomsCount} Baños</span>
    </div>
    <div>
      <CarIcon />
      <span>{garagesCount} Parqueaderos</span>
    </div>
    <div>
      <BlueprintIcon />
      <span>
        {externalArea}m<sup>2</sup> Total
      </span>
    </div>
    <div>
      <BlueprintIcon />
      <span>
        {internalArea}m<sup>2</sup> Cubierta
      </span>
    </div>
  </Container>
);

const Container = styled.div`
  display: grid;
  gap: 1rem;
  ${(props) => props.theme.mq.bigPhone} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${(props) => props.theme.mq.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
  div {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 14px 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);
    background-color: ${(props) => props.theme.colors.lightSurface};
    svg {
      height: 24px;
      margin-right: 1rem;
      g,
      path {
        fill: ${(props) => props.theme.colors.secondary};
      }
    }
  }
`;

export default React.memo(PropertyDetailInfoGrid);
