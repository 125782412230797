import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { PropertyDetailFragment } from '../../../gatsby-graphql';
import { BaseContainer } from '../BaseComponents';
import PropertyCardMediaSlider from '../PropertyCardMediaSlider/PropertyCardMediaSlider';
import { formatPrice } from '../../utils';
import PropertySpecsGrid from '../PropertySpecsGrid/PropertySpecsGrid';
import Mapbox from '../Mapbox/Mapbox';
import PropertyDetailBreadcrumbs from '../PropertyDetailBreadcrumbs/PropertyDetailBreadcrumbs';
import PropertyDetailInfoGrid from '../PropertyDetailInfoGrid/PropertyDetailInfoGrid';
import PropertyDetailContact from '../PropertyDetailContact/PropertyDetailContact';
import PropertyDetailRichText from './PropertyDetailRichText';

const PropertyDetail: React.FC<
  PropertyDetailFragment & { locationHref: string }
> = ({
  name,
  images,
  badge,
  price,
  description,
  propertySpecs,
  mapLocation,
  operationType,
  propertyType,
  propertyLocation,
  bedroomsCount,
  bathroomsCount,
  garagesCount,
  externalArea,
  internalArea,
  propertyAgent,
  locationHref,
}) => {
  const containerElementRef = useRef<HTMLDivElement | null>(null);
  const [
    containerElement,
    setContainerElement,
  ] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerElementRef.current) {
      setContainerElement(containerElementRef.current);
    }
  }, []);
  return (
    <Container ref={containerElementRef}>
      <PropertyDetailBreadcrumbs
        {...{ operationType, propertyType, propertyLocation, name }}
      />
      <InnerContainer>
        <div>
          <PropertyCardMediaSlider
            imageResolver="featured"
            images={images}
            badge={badge}
          />
          <Content>
            <h1>{name}</h1>
            <span>{formatPrice(price as number)}</span>
            <PropertyLocation>{propertyLocation?.name}</PropertyLocation>
            <PropertyDetailInfoGrid
              {...{
                bedroomsCount,
                bathroomsCount,
                garagesCount,
                externalArea,
                internalArea,
                propertyType,
              }}
            />
            <h2>Descripción</h2>
            <PropertyDetailRichText
              data={
                description as RenderRichTextData<ContentfulRichTextGatsbyReference>
              }
            />
            {mapLocation?.lat && mapLocation?.lon && (
              <>
                <h2>Ubicación</h2>
                <Mapbox lat={mapLocation?.lat} lon={mapLocation?.lon} />
              </>
            )}
            <h2>Caracteristicas</h2>
            <PropertySpecsGrid specs={propertySpecs} />
          </Content>
        </div>
        <PropertyDetailContact
          {...{
            operationType,
            propertyType,
            propertyLocation,
            price,
            locationHref,
          }}
          agent={propertyAgent}
          parentElement={containerElement}
        />
      </InnerContainer>
    </Container>
  );
};

const Container = styled(BaseContainer)`
  margin-top: 32px;
  ${(props) => props.theme.mq.desktop} {
    margin-top: 78px;
    gap: 34px;
  }
`;

const InnerContainer = styled.div`
  ${(props) => props.theme.mq.desktop} {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 8fr 4fr;
    gap: 46px;
  }
`;

const Content = styled.div`
  width: 100%;
  h1 {
    font-size: 22px;
    line-height: 27px;
    color: ${(props) => props.theme.colors.dark.primary};
    font-weight: bold;
    margin: 32px 0 9px;
    ${(props) => props.theme.mq.tablet} {
      font-size: 28px;
      line-height: 33px;
    }
  }
  h1 + span:first-of-type {
    font-size: 22px;
    line-height: 27px;
    color: ${(props) => props.theme.colors.dark.primary};
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.helvetica};
    ${(props) => props.theme.mq.tablet} {
      font-size: 28px;
      line-height: 33px;
    }
  }
  h2 {
    font-size: 18px;
    line-height: 13px;
    margin: 40px 0 30px;
    color: ${(props) => props.theme.colors.dark.primary};
  }
`;

const PropertyLocation = styled.address`
  display: block;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  margin: 11px 0 27px;
  color: ${(props) => props.theme.colors.dark.primary};
  ${(props) => props.theme.mq.tablet} {
    font-size: 18px;
    line-height: 21px;
  }
`;

export default PropertyDetail;
