import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { PropertyDetailFragment } from '../../../gatsby-graphql';

interface IPropertyDetailBreadcrumbsProps {
  operationType: PropertyDetailFragment['operationType'];
  propertyType: PropertyDetailFragment['propertyType'];
  propertyLocation: PropertyDetailFragment['propertyLocation'];
  name: PropertyDetailFragment['name'];
}

const PropertyDetailBreadcrumbs: React.FunctionComponent<IPropertyDetailBreadcrumbsProps> = ({
  propertyType,
  propertyLocation,
  operationType,
  name,
}) => (
  <Container>
    <ul>
      <li>
        <Link to={`/${operationType?.slug}`}>{operationType?.name}</Link>
      </li>
      <li>
        <Link to={`/${operationType?.slug}/${propertyType?.slug}`}>
          {propertyType?.name}
        </Link>
      </li>
      <li>
        <Link
          to={`/${operationType?.slug}/${propertyType?.slug}/${propertyLocation?.slug}`}
        >
          {propertyLocation?.name}
        </Link>
      </li>
      <li>{name}</li>
    </ul>
  </Container>
);

const Container = styled.div`
  ul {
    margin-top: 0;
    list-style: none;
    padding: 0;

    li {
      display: inline;
      font-size: 14px;
      font-weight: 500;
      color: ${(props) => props.theme.colors.dark.tertiary};
      & + li:before {
        padding: 0 4px;
        content: '/\\00a0';
      }
      a {
        color: ${(props) => props.theme.colors.dark.secondary};
        text-decoration: none;
        &:hover {
          color: ${(props) => props.theme.colors.primary};
          text-decoration: underline;
        }
      }
    }
  }
`;

export default React.memo(PropertyDetailBreadcrumbs);
